import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05fd0b21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-view-template" }
const _hoisted_2 = { class: "svt-side-bar" }
const _hoisted_3 = { class: "svt-main-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_MetaButton = _resolveComponent("MetaButton")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewsInfo, (item) => {
        return (_openBlock(), _createBlock(_component_MetaButton, {
          class: _normalizeClass(["side-bar-btn", {
        selected:_ctx.$route.name === item.viewName
      }]),
          onClick: ($event: any) => (_ctx.$router.push({
      name:item.viewName
    }))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SvgIcon, {
              name: item.image
            }, null, 8, ["name"]),
            _createVNode(_component_SlideI18nText, {
              path: item.path
            }, null, 8, ["path"])
          ]),
          _: 2
        }, 1032, ["onClick", "class"]))
      }), 256))
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_router_view, { class: "sub-view" }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_component_FadeTransition, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.include }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ], 1032, ["include"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}