import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubViewTemplate = _resolveComponent("SubViewTemplate")!

  return (_openBlock(), _createBlock(_component_SubViewTemplate, {
    class: "official",
    "views-info": [
                       {
                         viewName:_ctx.ViewNames.AboutUs,
                         image:'info',
                         path:'aboutUs'
                       },
                       {
                         viewName:_ctx.ViewNames.TermsOfService,
                         image:'term',
                         path:'termsOfService'
                       },
                       // {
                       //   viewName:ViewNames.CopyrightComplaints,
                       //   image:'copyright',
                       //   path:'copyrightComplaints'
                       // }
                   ]
  }, null, 8, ["views-info"]))
}