
import {Options, Vue} from 'vue-class-component';
import SubViewTemplate from "@/views/templates/SubViewTemplate.vue";
import {ViewNames} from "@/router";

@Options<Official>({
  components: {SubViewTemplate},
  props: {}
})
export default class Official extends Vue {
  ViewNames = ViewNames;
}
