
import {Options, Vue} from 'vue-class-component';
import {PropType} from "vue";
import {ViewNames} from "@/router";
import MetaButton from "@/components/MetaButton.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";

@Options<SubViewTemplate>({
  components: {FadeTransition, MetaButton},
  props: {
    viewsInfo:{
      required:true,
      type:Array as PropType<{image:string,path:string,viewName:ViewNames}[]>
    },
    include:{
      required:false,
      type:[String,RegExp],
      default:''
    }
  }
})
export default class SubViewTemplate extends Vue {
  viewsInfo!:{image:string,path:string,viewName:ViewNames}[];
  include!:ViewNames;
}
